<template>
  <b-overlay :show="loading">
    <div class="formBoder">
      <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form @submit.prevent="handleSubmit(submitData)" @reset.prevent="reset" autocomplete="off">
          <b-row>
            <b-col lg="12" md="12" sm="12" xs="12">
              <ValidationProvider name="Parent" vid="parent_id">
                <b-form-group
                  label-for="parent_id"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  Section
                </template>
                <b-form-select
                  plain
                  v-model="formFiled.parent_id"
                  :options="bookSectionParentList"
                  id="parent_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                <template v-slot:first>
                <b-form-select-option :value=0>Select</b-form-select-option>
                </template>
                </b-form-select>
               <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
            </b-col>
            <b-col v-if="bookSectionSubParentList.length" lg="12" md="12" sm="12" xs="12">
              <ValidationProvider name="Sub Section">
                <b-form-group
                  label-for="sub_parent_id"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  Sub Section
                </template>
                <b-form-select
                  plain
                  v-model="formFiled.sub_parent_id"
                  :options="bookSectionSubParentList"
                  id="sub_parent_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                <template v-slot:first>
                <b-form-select-option :value=0>Select</b-form-select-option>
                </template>
                </b-form-select>
               <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
            </b-col>
            <b-col v-if="bookSectionSub2ParentList.length" lg="12" md="12" sm="12" xs="12">
              <ValidationProvider name="Sub 2 Section">
                <b-form-group
                  label-for="sub_parent_id"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  Sub 2 Section
                </template>
                <b-form-select
                  plain
                  v-model="formFiled.sub2_parent_id"
                  :options="bookSectionSub2ParentList"
                  id="sub_parent_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                <template v-slot:first>
                <b-form-select-option :value=0>Select</b-form-select-option>
                </template>
                </b-form-select>
               <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
            </b-col>
            <b-col v-if="bookSectionSub3ParentList.length" lg="12" md="12" sm="12" xs="12">
              <ValidationProvider name="Sub 2 Section">
                <b-form-group
                  label-for="sub_parent_id"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  Sub 3 Section
                </template>
                <b-form-select
                  plain
                  v-model="formFiled.sub3_parent_id"
                  :options="bookSectionSub3ParentList"
                  id="sub_parent_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                <template v-slot:first>
                <b-form-select-option :value=0>Select</b-form-select-option>
                </template>
                </b-form-select>
               <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
            </b-col>
            <b-col v-if="bookSectionSub4ParentList.length" lg="12" md="12" sm="12" xs="12">
              <ValidationProvider name="Sub 2 Section">
                <b-form-group
                  label-for="sub_parent_id"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  Sub 4 Section
                </template>
                <b-form-select
                  plain
                  v-model="formFiled.sub4_parent_id"
                  :options="bookSectionSub4ParentList"
                  id="sub_parent_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                <template v-slot:first>
                <b-form-select-option :value=0>Select</b-form-select-option>
                </template>
                </b-form-select>
               <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
            </b-col>
            <b-col v-if="bookSectionSub5ParentList.length" lg="12" md="12" sm="12" xs="12">
              <ValidationProvider name="Sub 2 Section">
                <b-form-group
                  label-for="sub_parent_id"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  Sub 5 Section
                </template>
                <b-form-select
                  plain
                  v-model="formFiled.sub5_parent_id"
                  :options="bookSectionSub5ParentList"
                  id="sub_parent_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                <template v-slot:first>
                <b-form-select-option :value=0>Select</b-form-select-option>
                </template>
                </b-form-select>
               <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
            </b-col>
            <b-col v-if="bookSectionSub6ParentList.length" lg="12" md="12" sm="12" xs="12">
              <ValidationProvider name="Sub 2 Section">
                <b-form-group
                  label-for="sub_parent_id"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  Sub 6 Section
                </template>
                <b-form-select
                  plain
                  v-model="formFiled.sub6_parent_id"
                  :options="bookSectionSub6ParentList"
                  id="sub_parent_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                <template v-slot:first>
                <b-form-select-option :value=0>Select</b-form-select-option>
                </template>
                </b-form-select>
               <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
            </b-col>
            <b-col v-if="bookSectionSub7ParentList.length" lg="12" md="12" sm="12" xs="12">
              <ValidationProvider name="Sub 7 Section">
                <b-form-group
                  label-for="sub_parent_id"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  Sub 7 Section
                </template>
                <b-form-select
                  plain
                  v-model="formFiled.sub7_parent_id"
                  :options="bookSectionSub7ParentList"
                  id="sub_parent_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                <template v-slot:first>
                <b-form-select-option :value=0>Select</b-form-select-option>
                </template>
                </b-form-select>
               <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
            </b-col>
            <b-col lg="12" md="12" sm="12" xs="12">
              <ValidationProvider name="Section Name" vid="section_name" rules="required" v-slot="{ errors }">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  id="section_name"
                  label-for="name"
                >
                <template v-slot:label>
                  Section Name
                </template>
                  <b-form-input
                    id="section_name"
                    v-model="formFiled.section_name"
                    placeholder="Enter Section Name"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="12" md="12" sm="12" xs="12">
              <ValidationProvider name="Section Title" vid="section_title" v-slot="{ errors }">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  id="section_title"
                  label-for="name"
                >
                <template v-slot:label>
                  Section Title
                </template>
                  <b-form-input
                    id="section_title"
                    v-model="formFiled.section_title"
                    placeholder="Enter Section Title"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="12" md="12" sm="12" xs="12">
              <ValidationProvider name="Serial No" vid="serial" v-slot="{ errors }">
                <b-form-group
                  class="row"
                  label-cols-sm="12"
                  id="serial"
                  label-for="name"
                >
                <template v-slot:label>
                  Serial No
                </template>
                  <b-form-input
                    type="number"
                    id="serial"
                    v-model="formFiled.serial"
                    placeholder="Enter Serial No"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-input>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col lg="12" md="12" sm="12" xs="12">
              <ValidationProvider name="Access Type" vid="access_type" rules="required">
                <b-form-group
                  label-for="access_type"
                  slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  Access Type
                </template>
                <b-form-select
                  plain
                  v-model="formFiled.access_type"
                  :options="accessTypeList"
                  id="access_type"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                <template v-slot:first>
                <b-form-select-option :value=0>Select</b-form-select-option>
                </template>
                </b-form-select>
               <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
            </b-col>
          </b-row>
        <div class="row mt-3">
          <div class="col-sm-3"></div>
          <div class="col text-right">
              <b-button type="submit" variant="primary" class="mr-2">{{ SaveButton }}</b-button>
              &nbsp;
              <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Cancel</b-button>
          </div>
        </div>
      </b-form>
      </ValidationObserver>
    </div>
  </b-overlay>
</template>
<script>
import RestApi, { baseURL } from '@/config'

export default {
  props: ['editItem'],
  data () {
    return {
      SaveButton: this.editItem ? 'Update' : 'Save',
      formFiled: {
        section_name: '',
        serial: '',
        parent_id: 0,
        sub_parent_id: 0,
        sub2_parent_id: 0,
        sub3_parent_id: 0,
        sub4_parent_id: 0,
        sub5_parent_id: 0,
        sub6_parent_id: 0,
        sub7_parent_id: 0,
        section_title: '',
        access_type: 'Paid'
      },
      errors: [],
      valid: null,
      loading: false,
      bookSectionFullList: [],
      bookSectionTreeList: [],
      bookSectionParentList: [],
      subsectionList: []
    }
  },
  created () {
    this.getBookParentListDropDown()
    if (this.editItem) {
      this.formFiled = this.editItem
      this.formFiled.access_type = this.formFiled.access_type === false ? 'Free' : 'Paid'
      this.$emit('loadList', true)
    }
  },
  computed: {
    accessTypeList: function () {
      const list = [
        { value: 'Free', text: 'Free' },
        { value: 'Paid', text: 'Paid' }
      ]
      return list
    },
    bookSectionSubParentList: function () {
      if (this.formFiled.parent_id) {
        return this.bookSectionFullList.filter(item => item.parent_id === this.formFiled.parent_id)
      } else {
        return []
      }
    },
    bookSectionSub2ParentList: function () {
      if (this.formFiled.sub_parent_id) {
        return this.bookSectionFullList.filter(item => item.parent_id === this.formFiled.sub_parent_id)
      } else {
        return []
      }
    },
    bookSectionSub3ParentList: function () {
      if (this.formFiled.sub2_parent_id) {
        return this.bookSectionFullList.filter(item => item.parent_id === this.formFiled.sub2_parent_id)
      } else {
        return []
      }
    },
    bookSectionSub4ParentList: function () {
      if (this.formFiled.sub3_parent_id) {
        return this.bookSectionFullList.filter(item => item.parent_id === this.formFiled.sub3_parent_id)
      } else {
        return []
      }
    },
    bookSectionSub5ParentList: function () {
      if (this.formFiled.sub4_parent_id) {
        return this.bookSectionFullList.filter(item => item.parent_id === this.formFiled.sub4_parent_id)
      } else {
        return []
      }
    },
    bookSectionSub6ParentList: function () {
      if (this.formFiled.sub5_parent_id) {
        return this.bookSectionFullList.filter(item => item.parent_id === this.formFiled.sub5_parent_id)
      } else {
        return []
      }
    },
    bookSectionSub7ParentList: function () {
      if (this.formFiled.sub6_parent_id) {
        return this.bookSectionFullList.filter(item => item.parent_id === this.formFiled.sub6_parent_id)
      } else {
        return []
      }
    }
  },
  methods: {
    getSubParentById (parentId) {
      if (!parentId) {
        return null
      } else {
        const bookSectionObj = this.bookSectionFullList.find(item => item.value === parentId)
        return bookSectionObj ? bookSectionObj.parent_id : null
      }
    },
    async submitData () {
      this.loading = true
      let result = ''
      if (this.formFiled.id) {
        result = await RestApi.postData(baseURL, 'api/v1/admin/ajax/update_book_section_data', this.formFiled)
      } else {
        result = await RestApi.postData(baseURL, 'api/v1/admin/ajax/store_book_section_data', this.formFiled)
      }
      this.loading = false
      if (result.success) {
        this.$emit('loadList', true)
        this.$toast.success({
          title: 'Success',
          message: result.message
        })
        this.$bvModal.hide('modal-1')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getBookParentListDropDown () {
      RestApi.getData(baseURL, 'api/v1/admin/ajax/get_book_section_full_list', null).then(response => {
        if (response.success) {
          this.bookSectionFullList = response.data
          this.bookSectionParentList = this.createNewDropdownList(response.data)
          if (this.editItem) {
            this.formFiled.sub_parent_id = this.getSubParentById(this.editItem.parent_id)
            this.formFiled.sub2_parent_id = this.getSubParentById(this.formFiled.sub_parent_id)
            this.formFiled.sub3_parent_id = this.getSubParentById(this.formFiled.sub2_parent_id)
            this.formFiled.sub4_parent_id = this.getSubParentById(this.formFiled.sub3_parent_id)
            this.formFiled.sub5_parent_id = this.getSubParentById(this.formFiled.sub4_parent_id)
            this.formFiled.sub6_parent_id = this.getSubParentById(this.formFiled.sub5_parent_id)
            this.formFiled.sub7_parent_id = this.getSubParentById(this.formFiled.sub6_parent_id)
            if (this.formFiled.sub2_parent_id) {
              this.formFiled.parent_id = this.formFiled.sub2_parent_id
            } else if (this.formFiled.sub_parent_id) {
              const parentId = this.formFiled.parent_id
              this.formFiled.parent_id = this.formFiled.sub_parent_id
              this.formFiled.sub_parent_id = parentId
            }
          }
        } else {
          this.bookSectionFullList = []
        }
      })
    },
    createNewDropdownList (list, parentId = null) {
      const bookSectionList = []

      let mybookSectionList

      if (parentId === null) {
        mybookSectionList = list.filter(item => item.parent_id === undefined || item.parent_id === null)
      } else {
        mybookSectionList = list.filter(item => item.parent_id === parentId)
      }

      mybookSectionList.map(item => {
        bookSectionList.push({
          value: item.value,
          text: item.text,
          active: item.active,
          parent_id: item.parent_id,
          children: this.createNewDropdownList(list, item.value)
        })
      })
      return bookSectionList
    }
  }
}
</script>
<style>
 .formBoder {
    border: 1px solid;
    margin: 5px;
    padding: 35px;
    font-size: 13px
 }
 .input-group-text {
   height: 30.5px!important;
 }
</style>
